/** @jsx jsx */
import { jsx } from 'theme-ui'

const Hero = ({ children }) => {
  return (
    <div sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <div
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'flex-start',
          justifyContent: 'center',
          flexDirection: 'column',
          '*': {
            color: 'background',
            width: '100%',
            textAlign: 'left',
          },
          // pt: [2, 5, 5],
          // pb: [2, 5, 5],
          py: 3,
          minHeight: '280px',
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default Hero
