/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Flex, Box, Text } from '@theme-ui/components'
import NavBar from './navbar'
import Hero from './hero'
import TitleAndSubtitle from './title-and-subtitle'
import EmailSubscribeForm from './email-subscribe-form'
import {
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TwitterShareButton,
} from 'react-share'

import { IconContext } from 'react-icons'
import { FaFacebookSquare, FaRedditSquare, FaLinkedin, FaTwitterSquare } from 'react-icons/fa'
import moment from 'moment'

const shareButtonSize = '24px'

const ShareButtonContainer = ({ children }) => {
  return <Box sx={{ width: shareButtonSize, height: shareButtonSize }}>{children}</Box>
}

const TitleAndSubtitleWithSubscribeForm = ({ title, subtitle }) => {
  return (
    <Flex sx={{ width: '100%', flexDirection: 'row', alignItems: 'center' }}>
      <Box sx={{ width: ['100%', '50%'], mr: 2 }}>
        <TitleAndSubtitle title={title} subtitle={subtitle} />
      </Box>
      <Box sx={{ width: '50%', display: ['none', 'block'] }}>
        <EmailSubscribeForm />
      </Box>
    </Flex>
  )
}

const Header = ({
  title,
  subtitle,
  includeSubscribeForm = false,
  shareUrl,
  includeShareButtons = false,
  date,
}) => {
  return (
    <div
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        backgroundColor: 'primary',
        px: [2, 3, 3],
      }}
    >
      <div
        sx={{
          width: ['100%', '100%', '960px'],
        }}
      >
        <NavBar />
        <Hero>
          <Box
            sx={{
              mt: includeShareButtons ? [3, 4, 4] : 0,
              mb: includeShareButtons ? [3, 4, 4] : 0,
            }}
          >
            {includeSubscribeForm ? (
              <TitleAndSubtitleWithSubscribeForm title={title} subtitle={subtitle} />
            ) : (
              <TitleAndSubtitle title={title} subtitle={subtitle} />
            )}
            {includeShareButtons && (
              <IconContext.Provider value={{ size: shareButtonSize }}>
                <Flex
                  sx={{
                    mt: 3,
                    mb: 3,
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    '& > :not(:last-child)': { mb: 1 },
                    button: { outline: 'none' },
                  }}
                >
                  <Text sx={{ fontSize: 1, width: 'auto', whiteSpace: 'nowrap' }}>
                    {moment(date, 'YYYY-MM-DD').format('MMM DD, YYYY')}
                  </Text>
                  <Flex
                    sx={{
                      width: 'auto',
                      flexDirection: 'row',
                      '& > :not(:last-child)': { mr: 2 },
                    }}
                  >
                    <ShareButtonContainer>
                      <FacebookShareButton url={shareUrl}>
                        <FaFacebookSquare />
                      </FacebookShareButton>
                    </ShareButtonContainer>
                    <ShareButtonContainer>
                      <TwitterShareButton url={shareUrl} title={title}>
                        <FaTwitterSquare />
                      </TwitterShareButton>
                    </ShareButtonContainer>
                    <ShareButtonContainer>
                      <RedditShareButton url={shareUrl} title={title}>
                        <FaRedditSquare />
                      </RedditShareButton>
                    </ShareButtonContainer>
                    <ShareButtonContainer>
                      <LinkedinShareButton url={shareUrl} title={title} source="Get Fluent Spanish">
                        <FaLinkedin />
                      </LinkedinShareButton>
                    </ShareButtonContainer>
                  </Flex>
                </Flex>
              </IconContext.Provider>
            )}
          </Box>
        </Hero>
      </div>
    </div>
  )
}

export default Header
