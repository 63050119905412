/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Box } from '@theme-ui/components'

const TitleAndSubtitle = ({ title, subtitle }) => {
  return (
    <Box>
      <h1
        sx={{
          mt: 0,
          mb: 1,
          lineHeight: 1.125,
        }}
      >
        {title}
      </h1>
      <h2
        sx={{
          mt: 0,
          lineHeight: 1.25,
          fontSize: '20px',
          fontWeight: 400,
          color: 'subtitle',
        }}
      >
        {subtitle}
      </h2>
    </Box>
  )
}

export default TitleAndSubtitle
