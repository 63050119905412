/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Card, Input, Button, Box, Text } from '@theme-ui/components'
import { Fragment } from 'react'

const EmailSubscribeForm = () => {
  return (
    <Fragment>
      <Card sx={{ backgroundColor: 'white', borderRadius: 0, fontSize: 2, p: 3, width: '100%' }}>
        <form
          action="https://getfluentspanish.us20.list-manage.com/subscribe/post?u=e927b24852709a649f0a76161&amp;id=9750a2db31"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
          noValidate
        >
          <Text sx={{ color: 'text', mb: 2, mt: 1 }}>We're just getting started!</Text>
          <Text sx={{ color: 'text', mb: 2 }}>
            Make sure not to miss out on our new material by subscribing to our newsletter.
          </Text>
          <Input
            type="email"
            name="EMAIL"
            id="mce-EMAIL"
            sx={{ mb: 2, height: '36px', color: 'text' }}
            placeholder="Email"
          ></Input>
          <Button
            sx={{
              backgroundColor: 'info',
              width: 'auto',
              height: '36px',
              px: '8px',
              py: '0px',
            }}
            type="submit"
            name="subscribe"
            id="mc-embedded-subscribe"
          >
            Subscribe
          </Button>
          <div style={{ position: 'absolute', left: '-5000px', ariaHidden: true }}>
            <input type="text" name="b_e927b24852709a649f0a76161_9750a2db31" tabIndex="-1" />
          </div>
        </form>
      </Card>
    </Fragment>
  )
}

export default EmailSubscribeForm
